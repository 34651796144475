import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { FaBars, FaTerminal } from 'react-icons/fa'
// import logo from "../assets/logo.svg"
import { Navbar, NavDropdown } from 'react-bootstrap'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Nav from 'react-bootstrap/Nav'
import Links from '../constants/links'
import SocialLinks from '../constants/socialLinks'
import styled from 'styled-components' // 💅
import Breadcrumb from 'react-bootstrap/Breadcrumb'

if (typeof window !== `undefined`) {
  window.addEventListener('scroll', function () {
    let pageOffset = window.pageYOffset
    const navbar = document.getElementById('navbar')

    if (navbar != null && pageOffset > 0) {
      navbar.classList.add('nav-box-shadow')
    } else {
      navbar && navbar.classList.remove('nav-box-shadow')
    }
  })
}

export const Header = ({ siteTitle, toggle }) => (
  <StyledHeaderComponent>
    <Navbar id="navbar" fixed="top">
      <Navbar.Brand>
        <Link
          to="/"
          className="nav-logo"
          style={{
            fontFamily: `Roboto Mono`,
            fontSize: '24px',
            lineHeight: '48px',
            paddingTop: '22px',
            paddingLeft: '22px',
            color: `#65D9E9`,
            textDecoration: `none`,
          }}
        >
          <FaTerminal></FaTerminal>infosam.space
          {/* <img src={'/logo1.png'} alt={siteTitle} /> */}
        </Link>
      </Navbar.Brand>
      {/* <div className="nav-header"> */}
      <button className="toggle-btn" onClick={toggle}>
        <FaBars></FaBars>
      </button>
      {/* <div className="nav-right">
          <Links styleClass="nav-links" />
          <SocialLinks styleClass="nav-icons" />
        </div> */}

      {/* <Nav className="pull-right"> */}
      {/* <Nav.Link href="#home">Home</Nav.Link>
        <Nav.Link href="#link">Link</Nav.Link> */}
      {/* <TiThMenu></TiThMenu> */}
      {/* <DropdownButton alignRight title="explore" id="basic-nav-dropdown">
          <Link to="/posts" className="nav-links">
            Publications
          </Link>
          <NavDropdown.Divider />
          <Link to="/post" className="nav-links">
            Projects
          </Link>
        </DropdownButton>
      </Nav> */}

      <Breadcrumb>
        {/* <Breadcrumb.Item href="/" active>home</Breadcrumb.Item> */}
        <Breadcrumb.Item href="/posts">/explore</Breadcrumb.Item>
      </Breadcrumb>
    </Navbar>
  </StyledHeaderComponent>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `infosam.space`,
}

const StyledHeaderComponent = styled.div`
  // padding-top: 30px;
  button {
    // border: 1px solid #65d9e9;
    border-radius: 22px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border: 2px solid transparent;

    &:hover,
    &:focus,
    &:visited {
      background: #65d9e9 !important;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
      border: 2px solid transparent;
      cursor: pointer;
      outline: 0;
    }
  }
  .btn-primary:focus {
    box-shadow: none;
  }
  #basic-nav-dropdown {
    text-transform: lowercase;
    font-family: 'Open Sans', sans-serif;
    background: #65d9e9;
    border-color: #65d9e9;
    color: #111;
  }
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }
  .breadcrumb {
    font-family: 'Roboto Mono', monospace;
    margin-bottom: 40px;
    padding: 0;
  }
  .breadcrumb a {
    padding-right: 12px;
  }
  .breadcrumb-item a,
  .breadcrumb-item.active {
    /* color: #7F00FF; */
    color: #65d9e9;
    font-size: 24px;
  }
  .breadcrumb-item a:hover {
    // border-bottom: 3px solid red;
    text-decoration: underline;
  }
  // .breadcrumb-item.active {
  //   border-bottom: 3px solid red;
  // }
  .breadcrumb-item + .breadcrumb-item::before {
    font-size: 24px;
  }
  li {
    margin: 0;
  }
  nav {
    // background: #ccc;
    border-radius: 5px;
    // height: 60px;
    justify-content: space-between;
    line-height: 1.5;
    margin-top: 40px;

    .navbar-nav {
      margin-right: 30px;
      .nav-link {
        font-family: OpenSans, sans-serif;
        font-size: 22px;

        color: white;
        &:hover,
        &:focus,
        &:visited {
          cursor: pointer;
          color: #fff;
          outline: 0;
        }
      }
    }
  }
  .nav-box-shadow {
    margin-top: 0;
    border: 2px solid #65d9e9 !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }
  .navbar {
    // height: 98px;
    height: 64px;
    padding-top: 20px;
    padding-right: 60px;
    padding-bottom: 20px;
    background-color: #edebeb;
    border: 2px solid transparent;
  }
  .nav-logo img {
    margin-left: 40px;
    height: 60px;
    margin-top: 14px;
  }
  .navbar-brand {
    margin-bottom: 2rem;
  }
  .nav-links {
    text-decoration: none;
    padding-left: 19px;
    padding-right: 19px;
  }
  .dropdown-divider {
    margin-right: 0.89rem;
    margin-left: 0.89rem;
  }
  .menu-icon {
    color: #65d9e9;
    font-size: 44px;
  }
  @media (min-width: 576px) {
    & {
    }
  }
`
