/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { Header } from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'
import styled from 'styled-components' // 💅

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <StyledLayoutComponent>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Header toggle={toggle} />
      <main>{children}</main>
      <Footer />
    </StyledLayoutComponent>
  )
}

export default Layout

const StyledLayoutComponent = styled.div`
  padding: 0;
  margin: 0;
  .banner {
    // margin-top: 235px;
  }
`
